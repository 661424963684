import { environment as original } from './environment.dev.boston-pizza-style';

export const environment = {
  ...original,
  name: 'dev-boston-pizza-style-cw-off',
  backgroundAdPlayerColor: '#a10f25',
  featureToggle: {
    ...original.featureToggle,
    withCallWaiter: false,
    withCallWaiterToPay: true,
    tabBarAutoHide: false,
    idleDelay: 30
  }
};
